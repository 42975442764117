/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50 : #E1E9FF,
  100 : #B5C9FF,
  200 : #84A5FF,
  300 : #5381FF,
  400 : #2E66FF,
  500 : #094BFF,
  600 : #0844FF,
  700 : #063BFF,
  800 : #0533FF,
  900 : #0223FF,
  A100 : #FFFFFF,
  A200 : #F2F3FF,
  A400 : #BFC5FF,
  A700 : #A6ADFF,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-secondary: (
  50 : #FBE2E7,
  100 : #F5B8C4,
  200 : #EE889D,
  300 : #E75876,
  400 : #E23558,
  500 : #DD113B,
  600 : #D90F35,
  700 : #D40C2D,
  800 : #CF0A26,
  900 : #C70519,
  A100 : #FFF0F1,
  A200 : #FFBDC1,
  A400 : #FF8A91,
  A700 : #FF7079,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-tertiary: (
  50 : #FFFFFF,
  100 : #FFFFFF,
  200 : #FFFFFF,
  300 : #FFFFFF,
  400 : #FFFFFF,
  500 : #FFFFFF,
  600 : #FFFFFF,
  700 : #FFFFFF,
  800 : #FFFFFF,
  900 : #FFFFFF,
  A100 : #FFFFFF,
  A200 : #FFFFFF,
  A400 : #FFFFFF,
  A700 : #FFFFFF,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-positive: (
  50 : #e5f8f3,
  100 : #bfede2,
  200 : #95e1cf,
  300 : #6ad5bc,
  400 : #4accad,
  500 : #2ac39f,
  600 : #25bd97,
  700 : #1fb58d,
  800 : #19ae83,
  900 : #0fa172,
  A100 : #d0ffef,
  A200 : #9dffdd,
  A400 : #6affcb,
  A700 : #51ffc2,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-negative: (
  50 : #faeced,
  100 : #f3cfd3,
  200 : #ebafb6,
  300 : #e38f99,
  400 : #dd7783,
  500 : #d75f6d,
  600 : #d35765,
  700 : #cd4d5a,
  800 : #c74350,
  900 : #be323e,
  A100 : #ffffff,
  A200 : #ffd1d5,
  A400 : #ff9ea5,
  A700 : #ff858e,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-objective: (
  50 : #fbfaea,
  100 : #f5f3cb,
  200 : #eeeba9,
  300 : #e7e286,
  400 : #e1dc6c,
  500 : #dcd652,
  600 : #d8d14b,
  700 : #d3cc41,
  800 : #cec638,
  900 : #c5bc28,
  A100 : #ffffff,
  A200 : #fffcd0,
  A400 : #fff99d,
  A700 : #fff784,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

