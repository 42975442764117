
.ng-select .ng-select-container {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 5px 5px 0 10px;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  &.ng-select-container:after {
    border-bottom: 2px solid $negative;
  }

  .ng-value-container .ng-placeholder {
    color: $negative;
  }
}

.ng-standard-select-label {
  font-size: smaller;
  white-space: nowrap;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: red;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
}

.ng-select.select-short-dropdown {
  .ng-dropdown-panel-items {
    max-height: 120px;

    @include media("<desktop") {
      max-height: 240px;
    }
  }
}

.ng-select.nationality-select {
  .ng-dropdown-panel-items {
    z-index: 999;
    max-height: 132px;
  }
}

.ng-select.company-select,
.ng-select.application_status-select,
.ng-select.creator_list-select,
.ng-select.user_process_form-select,
.ng-select.companies_list-select,
.ng-select.soring-list-select
{
  @include fontSizeFactored(12px);
  font-family: Roboto, "Helvetica Neue", sans-serif;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: $grey;
    -webkit-box-shadow: inset 0 0 6px $grey;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $grey;
    -webkit-box-shadow: inset 0 0 6px $grey;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: $grey;
  }

  .ng-select-container {
    background: none;
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: transparent;
    box-shadow: none;
  }

  .ng-dropdown-panel {
    //border-radius: 20px;

    .ng-dropdown-panel-items {

      .ng-option-selected {
        background-color: $primary;
        color: $white;
      }

      .ng-option-marked {
        background-color: $secondary;
        color: $white;
      }
    }
  }
}

.user_process_form-input {
  width: 49.25vw;
  border: none;
  border-bottom: 1px solid $grey;
  margin-top: 2vh;
  padding: .4375em .4375em;
  font-size: calc(12px + var(--font-factor));
  font-family: Roboto, "Helvetica Neue", sans-serif;
  outline: none;
}
