$inactiveFont: $grey_darkest;
$inactiveBackground: $white;
$inactiveBorder: $grey;

$semiPostFont: $white;
$semiPostBackground: #3fb5f8;
$semiPostBorder: #3fb5f8;

$standardFont: #fff;
$standardBackground: #2b5ee0;
$standardBorder: #2b5ee0;

$ukrFont: #0057b7;
$ukrBackground: #ffd700;
$ukrBorder: #0057b7;

.gen-pills-container-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .pill {
    @include fontSizeFactored(11px);
    color: $inactiveFont;
    background-color: $inactiveBackground;
    border: 2px solid $inactiveBorder;
    padding: 5px 8px;
    border-radius: 10px;
    min-width: 100px;
    margin: 0 2px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 4px $grey;

    @include media('<phone') {
      min-width: 60px;
    }

    &.disallowed {
      cursor: not-allowed;
      background-color: $grey;
      opacity: 0.5;
      color: $grey_darkest;
      border-color: $grey;
      text-decoration: line-through;
    }

    &:hover:not(.disallowed),
    &.enabled:not(.disallowed) {
      box-shadow: unset;

      &.standard {
        background-color: $standardBackground;
        border-color: $standardBorder;
        color: white;
      }

      &.semi_post {
        background-color: $semiPostBackground;
        border-color: $semiPostBorder;
        color: $semiPostFont;
      }

      &.ukr {
        background-color: $ukrBackground;
        border-color: $ukrBorder;
        color: $ukrFont;
        font-weight: 500;
      }
    }
  }
}

.gen-pills-container-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .pill {
    @include fontSizeFactored(10px);
    color: $inactiveFont;
    border: 2px solid $inactiveBorder;
    opacity: 0.9;
    padding: 2px 4px;
    border-radius: 10px;
    min-width: 60px;
    text-align: center;
    margin-right: 5px;

    &.no-color {
      color: $grey_darkest;
      border: unset;
      background-color: unset;
      @include fontSizeFactored(12px);
    }

    &.standard {
      background-color: $standardBackground;
      border-color: $standardBorder;
      color: $standardFont;
    }

    &.semi_post {
      background-color: $semiPostBackground;
      border-color: $semiPostBorder;
      color: $semiPostFont;
    }

    &.ukr {
      background-color: $ukrBackground;
      border-color: $ukrBorder;
      color: $ukrFont;
      font-weight: 500;
    }

    &.disabled-pill {
      background-color: $grey;
      border-color: $grey;
      color: $grey_darkest;
      text-decoration: line-through;
      font-weight: 500;
    }

    &.long_resi {
      background-color: $secondary_opacity;
      border-color: $action_opacity;
      color: $white;
    }
    &.perm_stay {
      background-color: $primary_dark;
      border-color: $grey_lighter;
      color: $white;
    }
    &.temp_premit {
      background-color: $white;
      border-color: $tertiary;
      color: $grey_darkest;
    }
  }
}
