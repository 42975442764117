@import "/src/scss/breakpoints.scss";
@import "/src/scss/variables.scss";

@import "ng-zorro-antd/style/index.min.css";
@import "ng-zorro-antd/tabs/style/index.min.css";
@import "ng-zorro-antd/spin/style/index.min.css";
@import "ng-zorro-antd/popconfirm/style/index.min.css";
@import "ng-zorro-antd/message/style/index.min.css";
@import "ng-zorro-antd/switch/style/index.min.css";
@import "ng-zorro-antd/modal/style/index.min.css";
@import "ng-zorro-antd/empty/style/index.min.css";
@import "ng-zorro-antd/button/style/index.min.css";
@import "ng-zorro-antd/divider/style/index.min.css";
@import "ng-zorro-antd/grid/style/index.min.css";

@import "ng-zorro-antd/form/style/index.min.css";
@import "ng-zorro-antd/radio/style/index.min.css";
@import "ng-zorro-antd/input/style/index.min.css";
@import "ng-zorro-antd/input-number/style/index.min.css";
@import "ng-zorro-antd/select/style/index.min.css";
@import "ng-zorro-antd/checkbox/style/index.min.css";
@import "ng-zorro-antd/cascader/style/index.min.css";
@import "ng-zorro-antd/date-picker/style/index.min.css";
@import "ng-zorro-antd/dropdown/style/index.min.css";
@import "ng-zorro-antd/tooltip/style/index.min.css";
@import "ng-zorro-antd/badge/style/index.min.css";
@import "ng-zorro-antd/steps/style/index.min.css";
@import "ng-zorro-antd/popover/style/index.min.css";
@import "ng-zorro-antd/affix/style/index.min.css";
@import "ng-zorro-antd/drawer/style/index.min.css";
@import "ng-zorro-antd/collapse/style/index.min.css";
@import "ng-zorro-antd/image/style/index.min.css";
@import "ng-zorro-antd/result/style/index.min.css";
@import "ng-zorro-antd/upload/style/index.min.css";
@import "ng-zorro-antd/menu/style/index.min.css";

.ant-btn-primary {
  background-color: $primary;
  border-color: $primary_dark;
  color: $white;
}

.ant-btn-primary:hover {
  background-color: $primary;
  border-color: $primary_dark;
  color: $white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary;
  font-weight: 500;
}

.ant-tabs-tab-btn:hover {
  color: $primary;
}

.ant-btn-link {
  color: $primary;
}

.ant-switch-checked {
  background-color: $primary;
}

*,
::after,
::before {
  box-sizing: inherit;
}

.ant-cascader-dropdown {
  &.ant-select-dropdown {
    width: 80%;
    left: 4vw;
  }
}

.position-cascader-column {
  &.ant-cascader-menu {
    height: 280px;
  }

  .ant-cascader-menu-item {
    display: flex;
    max-width: 300px;
    align-items: center;
    padding: 5px 12px;
    overflow: hidden;
    line-height: 22px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input,
.ant-picker,
.ant-input-number {
  border-radius: 8px;
}

.ant-tooltip {
  max-width: 190px;
  font-size: 11px;
  line-height: 13px;
}

.ant-tooltip-inner {
  border-radius: 8px;
  background-color: #007cd7cf;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #007cd7cf;
}

.ant-tabs-tab,
.ant-tabs-tab + .ant-tabs-tab {
  @include media(">=desktop") {
    margin: 0 3vw;
  }

  @include media("<desktop") {
    margin: 0 20px;
  }
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  width: 100%;
}

.appointment-time-picker {
  .ant-picker-footer {
    .ant-picker-ranges {
      .ant-picker-now {
        display: none;
      }
    }
  }
}
