.stepper-container {
  margin-bottom: 200px;

  @include media(">=desktop") {
    max-width: 70vw;
  }
  @include media("<desktop") {
    max-width: 100vw;
  }

  .mat-vertical-stepper-header {
    height: auto;
    padding: 16px 16px 16px 0;
  }

  .mat-vertical-content-container {
    margin-left: 13px;
  }

  .mat-vertical-stepper-header .mat-step-icon {
    margin-right: 3.6vw;
    width: 25px;
    height: 25px;
    @include fontSizeFactored(12px);
    line-height: 13px;
    border: 2px solid $secondary;
    font-weight: 500;

    &.mat-step-icon-state-done {
      background-color: $primary;
      color: white;
      border-color: $primary;
    }

    &.mat-step-icon-selected {
      background-color: #fff;
      color: $primary;
      border-color: $primary;
    }

    &.mat-step-icon-state-warn,
    &.mat-step-icon-state-error {
      background-color: $white;
      color: $negative;
      border-color: $negative;
    }

    &.mat-step-icon-state-waiting {
      background-color: $white;
      border-color: $objective;
      color: $objective;
    }

    &.mat-step-icon-state-number {
      background-color: $grey_dark;
      border-color: $grey_dark;
      color: white;
    }
  }

  .mat-vertical-content {
    padding: 0 0 24px 4.5vw;

    @include media("<desktop") {
      padding: 0 5.5vw 24px 2vw;
    }
  }

  .mat-stepper-vertical-line::before {
    display: none;

    @include media("<500px") {
      display: none;
    }
  }

  .mat-step-header:hover {
    background-color: #3fb4f811 !important;
  }

  .mat-step-header.cdk-program-focused {
    background-color: #3fb4f811;
  }

  .mat-step-header.cdk-mouse-focused {
    background-color: #3fb4f811;
  }

  .mat-step-header.cdk-focused {
    background-color: #3fb4f811;
  }
}

.mat-step-label-selected {
  white-space: unset !important;
}

.mat-step-label {
  width: 90%;
  white-space: unset !important;
}

.mat-step-text-label {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media("<500px") {
    white-space: unset !important;
  }
}

.mat-step-text-label,
.step-header-text {
  @include fontSizeFactored(14px);
  line-height: 22px;
  font-weight: 500;
  color: $black;


  @include media("<desktop") {
    @include fontSizeFactored(12px);
    line-height: 20px;
  }

  @include media("<500px") {
    @include fontSizeFactored(12px);
    font-weight: 400;
    line-height: 16px;
  }

  .guide-open-button {
    width: 19px;
    height: 19px;
    color: $grey;
    @include fontSizeFactored(19px);
    margin-right: 9.5vw;
    transition: all 150ms ease-in-out;

    @include media('<=desktop') {
      display: none;
    }

    &:hover {
      color: $primary;
      width: 28px;
      height: 28px;
      @include fontSizeFactored(28px);

    }
  }
}
