.disabled-menu-item-tooltip {
  @include fontSizeFactored(12px);
  line-height: 21px;
  max-width: 220px;

  .ant-tooltip-inner {
    border-radius: 8px;
    background-color: #007dd7e7;
  }
}
.articles-hint-tooltip {
  @include fontSizeFactored(12px);
  line-height: 21px;
  max-width: 300px;

  .ant-tooltip-inner {
    border-radius: 8px;
    background-color: #007dd7e7;
  }
}
